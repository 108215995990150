export enum EnumEtapeCleStatut {
  VALIDEE = 'VALIDEE',
  EN_COURS = 'EN_COURS',
  FERMEE = 'FERMEE', // @toremove: temp values
  ENVOYEE = 'ENVOYEE', // @toremove: temp values
}

export namespace EnumEtapeCleStatut {
  export function toString(statutEtape?: EnumEtapeCleStatut): string {
    switch (statutEtape) {
      case EnumEtapeCleStatut.VALIDEE:
        return 'Validée';
      case EnumEtapeCleStatut.EN_COURS:
        return 'En cours';
      case EnumEtapeCleStatut.FERMEE:
        return 'Fermée';
      case EnumEtapeCleStatut.ENVOYEE:
        return 'Envoyée';
      default:
        return '';
    }
  }
}

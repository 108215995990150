import { formatDate } from '@angular/common';

import { EnumEtapeCleStatut, EnumEtapeCleType, ProjetEtapeCle } from '@shared-ui';

function inferEtapeCleIndex(etapeCle: ProjetEtapeCle, etapesCle: readonly ProjetEtapeCle[]): number {
  const filteredSortedEtapesCle = etapesCle
    .filter(ec => ec.type === etapeCle.type)
    .sort((ec1, ec2) => new Date(ec1.dateVersementPrev).getTime() - new Date(ec2.dateVersementPrev).getTime());

  return filteredSortedEtapesCle.findIndex(ec => ec.id === etapeCle.id) + 1;
}

function isAvanceInitiale(etapeCle: ProjetEtapeCle): boolean {
  return [EnumEtapeCleType.AVANCE_INITIALE, EnumEtapeCleType.AVANCE_INITIALE_SANS_CP].includes(etapeCle?.type);
}

function buildEtapeCleTitle(etapeCle: ProjetEtapeCle, etapesCle: readonly ProjetEtapeCle[], dateFormat: string): string | undefined {
  if (!etapeCle) return;

  const date = formatDate(new Date(etapeCle.dateVersementReel || etapeCle.dateVersementPrev), dateFormat, 'fr-FR');

  switch (etapeCle.type) {
    case EnumEtapeCleType.AVANCE_INITIALE:
    case EnumEtapeCleType.AVANCE_INITIALE_SANS_CP:
      return EnumEtapeCleType.toString(EnumEtapeCleType.AVANCE_INITIALE);
    case EnumEtapeCleType.SOLDE:
      return `${EnumEtapeCleType.toString(EnumEtapeCleType.SOLDE)} ${date}`;
    default:
      return `${EnumEtapeCleType.toString(EnumEtapeCleType.ETAPE_CLE)} ${inferEtapeCleIndex(etapeCle, etapesCle)} ${date}`;
  }
}

function isEtapeCleEncours(etapeCle: ProjetEtapeCle) {
  return etapeCle?.statut === EnumEtapeCleStatut.EN_COURS;
}

export { buildEtapeCleTitle, isAvanceInitiale, isEtapeCleEncours };
